import React from 'react';
import Head from '../components/Head';
import Layout from '../layout/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout header={true}>
      <div className="min-h-screen container mx-auto px-4 my-6">
        <Head title="Privacy Policy" />
        <div className="bg-white my-12">
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              Last updated: June 28, 2024
            </h2>
            <div className="font-sans leading-7">
              <p>
                At Lizie Ecommerce, ne of our main priorities is the privacy of our visitors. This
                Privacy Policy document contains types of information that is
                collected and recorded by LizieStyle and how we use it.
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.we may
                receive additional information about you such as your name,
                email address, phone number, the contents of the message and/or
                attachments you may send us, and any other information you may
                choose to provide. When you register for an Account, we may ask
                for your contact information, including items such as name,
                company name, address, email address, and telephone number.
              </p>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in LizieStyle.
                we may receive additional information about you such as
                your name, email address, phone number, the contents of the
                message and/or attachments you may send us, we may ask for your
                contact information, including items such as name, company name,
                address, email address, and telephone number. This policy is not
                applicable to any information collected offline or via channels
                other than this website. Our Privacy Policy was created with the
                help of the Free Privacy Policy Generator.
              </p>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              Consent
            </h2>
            <div className="font-sans leading-7">
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              Information we collect
            </h2>
            <div className="font-sans leading-7">
              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information. If you contact us directly, we may receive
                additional information about you such may choose to provide.
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>
              <p>
                Please note that the Company will not ask you to share any
                sensitive data or information via email or telephone. If you
                receive any such request by email or telephone, please do not
                respond/divulge any sensitive data or information.
              </p>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              How we use your information
            </h2>
            <div className="font-sans leading-7">
              <p>
                We use the information we collect in various ways, including to:
              </p>

              <ol>
                <li>
                  1. Provide, operate, and maintain our website, to provide you
                  with updates and other information.
                </li>
                <li>
                  2. Improve, personalize, and expand our website,and other
                  information relating to the website.
                </li>
                <li>
                  3. Understand and analyze how you use our website, to provide
                  you with updates and other information relating to the
                  website.
                </li>
                <li>
                  4. Develop new products, services, features, and
                  functionality,and other information relating to the website.
                </li>
                <li>
                  5. Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates.
                </li>
                <li>
                  6. Send you emails. To provide you with updates and other
                  information relating to the website, and for marketing and
                  promotional purposes
                </li>
                <li>
                  7. Find and prevent fraud. To provide you with updates and
                  other information relating to the website, and for marketing
                  and promotional purposes
                </li>
              </ol>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              Log Files
            </h2>
            <div className="font-sans leading-7">
              <p>
                LizieStyle follows a standard procedure of using log
                files. These files log visitors when they visit websites. All
                hosting companies do this and a part of hosting. The information
                collected by log files include internet protocol (IP) addresses,
                browser type, Internet Service Provider (ISP), date and time
                stamp, referring/exit pages, and possibly the number of clicks.
                These are not linked to any information that is personally
                identifiable. The purpose of the information is for analyzing
                trends, administering the site, tracking users' movement on the
                website, and gathering demographic information.
              </p>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              Advertising Partners Privacy Policies
            </h2>
            <div className="font-sans leading-7">
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of LizieStyle. Third-party ad
                servers or ad networks uses technologies like cookies,
                JavaScript, or Web Beacons that are used in their respective
                advertisements and links that appear on LizieStyle, which
                are sent directly to users' browser. They automatically receive
                your IP address when this occurs. These technologies are used to
                measure the effectiveness of their advertising campaigns and/or
                to personalize the advertising content that you see on websites
                that you visit. Note that LizieStyle has no access to or
                control over these cookies that are used by third-party
                advertisers.
              </p>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              Third Party Privacy Policies
            </h2>
            <div className="font-sans leading-7">
              <p>
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on LizieStyle, 
                which are sent directly to users' browser. They
                automatically receive your IP address when this occurs. These
                technologies are used to measure the effectiveness of their
                advertising campaigns and/or to personalize the advertising
                content that you see on websites that you visit. Note that
                LizieStyle has no access to or control over these cookies
                that are used by third-party advertisers.
              </p>
              <p>
                LizieStyle's Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options. You
                can choose to disable cookies through your individual browser
                options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
              </p>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              CCPA Privacy Rights
            </h2>
            <div className="font-sans leading-7">
              <p>
                Under the CCPA, among other rights, California consumers have
                the right to: Request that a business that collects a consumer's
                personal data disclose the categories and specific pieces of
                personal data that a business has collected about consumers.
                Request that a business delete any personal data about the
                consumer that a business has collected. Request that a business
                that sells a consumer's personal data, not sell the consumer's
                personal data. If you make a request, we have one month to
                respond to you. If you would like to exercise any of these
                rights, please contact us.
              </p>
            </div>
          </div>
          <div className="mb-8 lg:mb-12 last:mb-0">
            <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold mb-2 lg:mb-4">
              Children's Information
            </h2>
            <div className="font-sans leading-7">
              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.Request that a business delete any personal data about
                the consumer that a business has collected. If you make a
                request, we have one month to respond to you. If you would like
                to exercise any of these rights, please contact us.
              </p>
              <p>
                LizieStyle does not knowingly collect any Personal
                Identifiable Information from children under the age of 13. If
                you think that your child provided this kind of information on
                our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such
                information from our records.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
