import React from 'react';
import Layout from '../layout/Layout';
import failed from '../images/failed_card.png';

function OrderFailed() {
  return (
    <Layout header={true}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            maxWidth: '600px',
            padding: '20px',
          }}
        >
          <img src={failed} alt="Success Icon" style={{ width: '100px', height: 'auto', margin: 'auto' }} />
          <h2 style={{ fontSize: '2.5rem', color: 'red', marginBottom: '1rem' }}>Order Failed!</h2>
          <p style={{ fontSize: '1.5rem', color: '#333', marginBottom: '1.5rem' }}>Unfortunately, we could not process your order. Try Again!!!</p>
        </div>
      </div>
    </Layout>
  )
}


export default OrderFailed;
