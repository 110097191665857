import React from 'react';
import Layout from '../layout/Layout';
import success from '../images/success_card.png'; // Adjusted path to your image

function OrderSuccess() {
  return (
    <Layout header={true}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'inherit  '
        }}
      >
        <div
          style={{
            textAlign: 'center',
            maxWidth: '600px',
            padding: '20px',
          }}
        >
          <img src={success} alt="Success Icon" style={{ width: '100px', height: 'auto', margin: 'auto' }} />
          <h2 style={{ fontSize: '2.5rem', color: 'green', marginBottom: '1rem' }}>Order Successful!</h2>
          <p style={{ fontSize: '1.5rem', color: '#333', marginBottom: '1.5rem' }}>Thank you for your order.</p>
        </div>
      </div>
    </Layout>
  );
}

export default OrderSuccess;
