import { RadioGroup } from '@headlessui/react';
import React, { useState } from 'react';
// import { productDetailsData } from '../Data/FilterData';
import { FreeMode, Thumbs } from 'swiper/modules';
import { MdArrowDropDownCircle } from "react-icons/md";
import axios from 'axios';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

const title = 'text-sm font-medium';


 


export function SizeRadio({ id, selected, setSelected }) { 
  const [variantSize, setVariantSize] = useState([]); 

  const getProductData = (id) => {
    axios.get(`/api/products/getProductData/${id}`)
      .then(function (response) { 
      setVariantSize([...response.data.variantInfo]);   
      // console.log(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });  
  }
 


  return (
    <div>
      {/* details */}
      <div className="flex items-center">
      <h2 className={title}>Choose your size</h2>
      <button onClick={() => getProductData(id)} className="ml-2">
        <MdArrowDropDownCircle style={{ fontSize: "25px" }} />
      </button>
    </div>
      {/* select */ }
      <RadioGroup value={selected} onChange={setSelected}>
        <div className="gap-4 flex items-center flex-wrap mt-4">
        {variantSize.length > 0 && (
          variantSize.map((item) => (
            <RadioGroup.Option key={item.title} value={item}>
              {({ checked }) => (
                <ul style={{ listStyleType: "none", padding: "2px 4px", borderRadius: "5px", border: "1px solid gray" }}>
                  <li>
                    <div style={{ display: "inline-block", marginRight: "10px" }}>
                      <div
                        style={{
                          backgroundColor: item.color,
                          fontSize: "10px",
                          width: "10px",
                          height: "10px",
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginRight: "5px"
                        }}
                      ></div>
                      <span>{item.title}</span>
                    </div>
                  </li>
                </ul>
              )}
            </RadioGroup.Option>
          ))
        )}

        </div>
      </RadioGroup>
    </div>
  );
}

// export function ColorRadio({ id, selected, setSelected }) {  
//   return (
//     <div>
//       {/* details */}
//       <h2 className={title}>Choose your colors</h2>
//       {/* select */}
//       <RadioGroup value={selected} onChange={setSelected}>
//         <div className="gap-4 flex-wrap flex items-center mt-4">
//           {variantColor.map((item) => (
//             <RadioGroup.Option key={item.title} value={item}>
//               {({ checked }) => (
//                 <div
//                   className={`flex-colo w-12 p-1 cursor-pointer h-12 rounded-full border ${
//                     checked ? ' border-main' : 'border-gray-300'
//                   }
//                       `}
//                 >
//                   <div
//                     style={{ backgroundColor: item.color }}
//                     className={` w-full h-full rounded-full`}
//                   ></div>
//                 </div>
//               )}
//             </RadioGroup.Option>
//           ))}
//         </div>
//       </RadioGroup>
//     </div>
//   );
// }

export function QuantityRadio({ quantity, setQuantity, stock }) {
  const classes =
    'border border-gray-300 rounded w-10 h-10 flex-colo disabled:bg-dryGray disabled:cursor-not-allowed';
  return (
    <div className="space-y-2">
      {/* details */}
      <h2 className={title}>Quantity</h2>
      {/* select */}
      <div className="flex-btn gap-4 border rounded-md w-full p-2 ">
        <button
          disabled={quantity === 1}
          onClick={() => setQuantity(quantity - 1)}
          className={classes}
        >
          -
        </button>
        <h2 className="text-lg font-semibold">{quantity}</h2>
        <button
          disabled={quantity === stock}
          onClick={() => setQuantity(quantity + 1)}
          className={classes}
        >
          +
        </button>
      </div>
    </div>
  );
}

export function ProductImages({ images }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <Swiper
        spaceBetween={10}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Thumbs]}
        className="mySwiper2"
      >
        {images?.map((image) => (
          <SwiperSlide>
            <img
              src={image}
              className="w-full h-[350px] sm:h-[450px] md:h-[600px] rounded-2xl object-cover"
              alt="product"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="mt-4"
      >
        {images?.map((image) => (
          <SwiperSlide>
            <img
              src={image}
              className="w-full h-24 md:h-32 rounded-lg border cursor-pointer object-cover"
              alt="product"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
