    import React, { useState }  from 'react';
    import { useParams } from 'react-router-dom';
    import axios from 'axios';
    import { toast } from "react-hot-toast";
    // import "react-hot-toast/dist/ReactToastify.css";

    const PayOrder = () => {
        const [otp, setOtp] = useState(""); // store otp values
        const { order_id, amountToBePaid } = useParams(); // get the params here

    // handle the button onclick
    const handlePayOrder = () => {
        const data = {
            password: otp
        };
        axios.post(`/api/lizieadmin/admin/${order_id}/${amountToBePaid}`, data)
        .then((result) => {
            if(result.data === 'Success'){
                toast.success("Payment Successful", {
                    position: 'top-center',
                    autoClose: 15000,
                })
            }
            else{
                toast.error(result.data, {
                    position: 'top-center',
                    autoClose: 15000,
                })
            }
            // Handle the result or update state as needed
        })
        .catch((error) => {
            console.error('Error paying order:', error);
            // Handle errors appropriately
        });
        
    };

    // event to handle the otp value
    const handleOTPChange = (e) => {
        setOtp(e.target.value);
        
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <input type='password' placeholder='Enter OTP Here' style={{ border: "1px solid #09D4FC"}} value={otp} onChange={handleOTPChange}></input> <br/>
        <button onClick={handlePayOrder} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#09D4FC', color: '#fff', border: 'none', borderRadius: '10px', cursor: 'pointer' }}>
            Pay Order
        </button>
        </div>
    );
    };

    export default PayOrder;
