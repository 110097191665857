import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { getAllRecentOrdersAction } from '../../Redux/Actions/OrderAction';
import { payRecentOrderAction } from '../../Redux/Actions/OrderAction';
import { SiContactlesspayment } from "react-icons/si";


function RecentOrders () {
  const Head = 'text-xs font-semibold px-6 py-2 uppercase';
  const Text = 'px-5 text-sm py-3 leading-6 whitespace-nowrap';
  const dispatch = useDispatch();

  // Redux state selectors
  const {
    allRecentOrders: { allOrders }
  } = useSelector((state) => state); // Update to match your actual state structure

  // Fetch all recent orders on component mount
  useEffect(() => {
    dispatch(getAllRecentOrdersAction());
  }, [dispatch]);

  // delete order
  const payHandler = (orderId, orderNum ) => {
    const data = {
      orderId: orderId,
      orderNum: orderNum
    }
    if (window.confirm('This order will be Paid!')) {
      dispatch(payRecentOrderAction(data));
    }
  };

  return (
    <div>
      {!allOrders ? ( 
        <p>No Recent Orders or Import First</p>
      ) :(
        <div className="w-full relative overflow-hidden overflow-x-scroll">
        <h2 className={`text-center m-2`} style={{color: "orangered", fontStyle: 'italic'}}><u>LISTS OF RECENT ORDERS BY CUSTOMERS - APPROVE PAYMENT</u></h2>
        <table className='able-auto min-w-full border border-deepest divide-y divide-gray-200'>
          <thead>
            <tr className='bg-deepest'>
              <th scope="col" className={`${Head} text-left`}>Order ID</th>
              <th scope="col" className={`${Head} text-center`}>Order Number</th>
              <th scope="col" className={`${Head} text-center`}>Order Status</th>
              <th scope="col" className={`${Head} text-center`}>Date Created</th>
              <th scope="col" className={`${Head} text-end`}>Action</th>
            </tr>
          </thead>
          <tbody className='className="bg-white divide-y divide-deepest'>
            {allOrders.map(order => (
              <tr key={order._id}>
                <td className={`${Text} font-medium`}>{order.orderId  }</td>
                <td className={`${Text} text-center`}>{order.orderNum}</td>
                <td className={`${Text} text-center`}>{order.status}</td>
                <td className={`${Text} text-center`}>{order.date}</td>
                <td className={`${Text} text-end float-end`}> 
               {
                <button
            onClick={() => payHandler(order.orderId, order.orderNum)} 
            className="border text-flash rounded flex-colo w-6 h-6"
          >
            <SiContactlesspayment style={{color: 'green'}}/>
          </button>
               }</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
    </div>
  );
};

export default RecentOrders;
