import React from 'react';
import Head from '../components/Head';
import Layout from '../layout/Layout';
import { Disclosure } from '@headlessui/react';
import { FiChevronUp } from 'react-icons/fi';

function FAQPage() {
  const FAQData = [
    {
      title: 'How does lsizieStyle ecommerce work?',
      desc: 'We are an exceptional store selling all fashion styled goods to customers and delivering on time to satisfaction.',
    },
    {
      title: 'Can I cancel my purchased goods?',
      desc: 'When you place an order, It is pending. You can cancel within that timeframe but after you pay for the order, It is automatic and your orders are already being processed.',
    },
    {
      title: 'Which payment method do you accept?',
      desc: 'We are partnered with Stripe for our payments and to give you access to all the methods available for payment in Europe, When paying, select your preferred option.',
    },
    {
      title: 'How do I track my Order?',
      desc: 'To track your orders, Go to your dashboard and In the table under delivery STATUS, click the link to follow and when it opens, scroll down to delivery section and view the dates, and status',
    },
    {
      title: 'Contacting Lizie Support For Help.',
      desc: 'On our contacts page, use any of the methods to reach out. We recommend email as we respond faster and after 24hrs of your issued problem.'
   },
    {
      title: 'Error on my payment, seeing -1 on shipping and total price.',
      desc: 'When you see -1 on your shipping price or total for your products, It means a shipping vessel has not been found for your order. Either try again later or delete the current order and make another order of products.'
    },
    {
      title: 'How long does it take to get my order / Delivery Time.',
      desc: 'Our delivery time is between 7-12 days, If you do not receive your order within the end of 12days, Kindly contact support for help, Sorry for the technical error.'
    },
    {
      title: 'Where we render our service ?',
      desc: 'Liziestyle offers classic fashion goods at standard quality, we offer services only to Italy for now hoping to expand our horizon.'
    },
  ];
  return (
    <Layout header={true}>
      <div className="min-h-screen container mx-auto px-4 my-6">
        <Head title="FAQ" />
        <div className="bg-white">
          <div className=" lg:py-20 py-10 lg:px-4">
            <div className="flex-rows">
              <div className="lg:w-4/6 w-full flex flex-col gap-4">
                {FAQData.map((q, i) => (
                  <Disclosure as="div" key={i}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-base font-medium text-left text-gray-600 focus:text-main bg-gray-50 hover:bg-deepest rounded-lg focus:outline-none">
                          <span>{q.title}</span>
                          <FiChevronUp
                            className={`${
                              open ? 'transform rotate-180 text-main' : ''
                            } w-5 h-5 text-gray-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-3 pb-8 text-sm leading-7 text-gray-500">
                          {q.desc}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FAQPage;
