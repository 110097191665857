import React from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Footer() {
  // const { userInfo } = useSelector((state) => state.userLogin);
  const Links = [
    {
      title: 'Company',
      links: [
        {
          link: '/about-us',
          name: 'About Us',
        },
        {
          link: '/faq',
          name: 'FAQ',
        },
        {
          link: '/policy',
          name: 'Policy',
        },
        {
          link: '/terms-condition',
          name: 'Condition',
        },
      ],
    },
    // {
    //   title: 'Top Category',
    //   links: [
    //     {
    //       link: '#',
    //       name: 'Men Outfit',
    //     },
    //     {
    //       link: '#',
    //       name: 'Shoes',
    //     },
    //     {
    //       link: '#',
    //       name: 'Women Outfit',
    //     },
    //     {
    //       link: '#',
    //       name: 'Swimwear',
    //     },
    //   ],
    // },
    // {
    //   title: 'My Account',
    //   links: [
    //     {
    //       link: userInfo ? '/dashboard' : '/registration',
    //       name: 'Dashboard',
    //     },
    //     {
    //       link: userInfo ? '/orders' : '/registration',
    //       name: 'My Orders',
    //     },
    //     {
    //       link: userInfo ? '/profile' : '/registration',
    //       name: 'Updated Profile',
    //     },
    //     {
    //       link: userInfo ? '/password' : '/registration',
    //       name: 'Change Password',
    //     },
    //   ],
    // },
  ];
  return (
    <div className="bg-gray-50 py-4 border-t-2">
      <div className="container mx-auto px-2">
        <div className="grid grid-cols-2 md:grid-cols-7 xl:grid-cols-12 gap-5 sm:gap-9 lg:gap-11 xl:gap-7 py-10 justify-between">
          {Links.map((l, i) => (
            <div
              key={i}
              className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3"
            >
              <h3 className="text-md lg:leading-7 font-medium mb-4 sm:mb-5 lg:mb-6 pb-0.5">
                {l.title}
              </h3>
              <ul className="text-sm flex flex-col space-y-3">
                {l.links.map((t, index) => (
                  <li className="flex items-baseline" key={index}>
                    <Link
                      to={t.link}
                      className="text-gray-600 inline-block w-full hover:text-main"
                    >
                      {t.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link to="/">
                  <img
                    className="w-2/4 object-contain h-12"
                    src="/images/logo.png"
                    alt="logo"
                    style={{ marginRight: "10px" }} // Adjust margin as needed
                  />
                </Link>
                <Link to="https://www.instagram.com/liziestyle_ecommerce/" style={{ display: "inline-block", marginLeft: "3px", marginRight: "3px" }}>
                  <img
                    className="w-2/4 object-contain h-12"
                    src="http://stansafaris.com/wp-content/plugins/cf7-email-add-on/admin/assets/images/instagram-icon.png"
                    alt='instagram'
                    style={{ display: "block" }} // Ensure the image is displayed as block
                  />
                </Link>
              </div>

            <p className="leading-7 font-sans text-sm text-gray-600 mt-3">
              <span>
                Griscom Street, <br /> Pennsylvania, U.S.A
              </span>
              {/* <br /> */}
              {/* <span>Text: +1 2</span> */}
              <br />
              <span>Contact Email: support@liziestyle.com</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
