import React from 'react';
import Titles from '../Titles';
import { BsCollectionFill } from 'react-icons/bs';
import Products from '../Products';
import { CardLoader } from '../Notifications/Loader';

function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;
  
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
  
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  
  return array;
}

function AfterProducts({ datas, loading }) {
  const Product = datas;
  return (
    <div className="sm:my-20 my-8">
      <Titles title="Shop More" Icon={BsCollectionFill} />
      <div
        data-aos="zoom-in-up"
        data-aos-offset="200"
        data-aos-delay="10"
        data-aos-duration="1000"
        className="grid sm:mt-10 mt-6 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4"
      >
       {loading || Product?.length === 0 ? (
  // If loading or no products are available, show loaders or a fallback UI
          Array.from(Array(8).keys()).map((s, i) => <CardLoader key={i} />)
        ) : (
      // If products are available, proceed with displaying the products
      (() => {
        const shuffledProducts = shuffle(Product ?? []);
        return shuffledProducts.slice(0, 8).map(p => (
          <Products bg={false} key={p._id} product={p} />
        ));
      })()
    )}  

      </div>
      
    </div>
    
  );
}

/* (
          // If products are available, proceed with displaying the products
          (() => {
            const shuffledProducts = shuffle(Product?.filter(p => p.tags.includes("kids")));
            return shuffledProducts.slice(0, 8).map(p => (
              <Products bg={false} key={p._id} product={p} />
            ));
          })()
        ) */

export default AfterProducts;
